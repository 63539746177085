$(function(){
  var mobile = false;

  if ($(".mobile").css("display") == "block") {
    mobile = true;
  }

  // $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
  // $$$$$$$$$$$$$$$  SCROLL MAGIC + ANIMAÇÕES  $$$$$$$$$$$$$$$$$$$$$$$$$$$$
  // $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$

  var controller = new ScrollMagic.Controller();

  // #######################################################################
  // #################  NAVBAR  ############################################
  // #######################################################################

  var logo_inicial = $(".logo-inicial-front");
  var logo_icon = $(".logo-icon-front");
  var navbar = $(".navbar-front");
  var navbarMenu = $(".navbar .btn");
  var landing_page = $(".landing-page");

  // var navbarAnim = new TimelineMax();

  // navbarAnim
  //   .to(logo_inicial, 0.2, { opacity: 0, ease: Power0.easeNone })
  //   .to(
  //     navbar,
  //     0.2,
  //     {
  //       height: "60px",
  //       backgroundColor: "#f5f5f5",
  //       color: "#a5c61d",
  //       ease: Power0.easeNone,
  //     },
  //     "-=0.1"
  //   )
  //   .to(navbarMenu, 0.2, { color: "#a5c61d", ease: Power0.easeNone }, "-=0.1")
  //   // .to(landing_page, 0.5, {paddingTop:'60px', ease:Power0.easeNone}, '-=0.3')
  //   .to(logo_icon, 0.2, { left: "5rem", ease: Power0.easeNone });

  // var magicNavbar = new ScrollMagic.Scene({
  //   triggerElement: ".landing-page-content",
  //   triggerHook: 0.25,
  // })
  //   .setTween(navbarAnim)
  //   .addIndicators({
  //     name: "navbar",
  //     color: "blue",
  //   })
  //   .addTo(controller);

  // var navbar = $(".navbar-front");


  // Add class to navbar (Cor, Padding e shadow)
  new ScrollMagic.Scene({
    triggerElement: "#front-reserva-form",
    triggerHook: 0.8,
  })
    .setClassToggle(".navbar-front", "top-nav-collapse")
    // .addIndicators({
    //   name: "shadow",
    //   color: "yellow",
    // })
    .addTo(controller);

  //  // Add class to .logo-texto (Width)
  //  new ScrollMagic.Scene({
  //   triggerElement: "#ten",
  //   triggerHook: 0.6,
  // })
  //   .setClassToggle('.logo-icon', 'logo-icon-small')
  //   .addTo(controller);

  // Add class to .logo-texto (Opacity)
  // new ScrollMagic.Scene({
  //   triggerElement: "#ten",
  //   triggerHook: 0.6,
  // })
  //   .setClassToggle('.logo-texto', 'opacity-1')
  //   .addTo(controller);

  // #######################################################################
  // #################  FIM NAVBAR  ########################################
  // #######################################################################

  // #######################################################################
  // #################  LANDING-PAGE  ######################################
  // #######################################################################

  var landingPage = new TimelineMax({ paused: true });
  landingPage
    .to(".landing-page-content", 0.5, { display: "block" })
    .from(".landing-page-content p", 0.5, {
      x: 500,
      opacity: 0,
      ease: Power0.easeNone,
    })
    .from(".landing-page-content a", 0.5, {
      y: 200,
      opacity: 0,
      ease: Power0.easeNone,
    });

  landingPage.play();

  $(".slider-slide").on(
    "afterChange",
    function (event, slick, currentSlide, nextSlide) {
      // var currentSlide = $(slick.$slides.get(currentSlide));
      landingPage.play();
    }
  );

  $(".slider-slide").on(
    "beforeChange",
    function (event, slick, currentSlide, nextSlide) {
      landingPage.restart();
    }
  );

  // #######################################################################
  // #############  FIM LANDING-PAGE  ######################################
  // #######################################################################

  // #######################################################################
  // #################  PARALLAX   #########################################
  // #######################################################################

  //if(!mobile){
  $(".parallax-parallax").each(function () {
    var parallaxEquipa = new TimelineMax();
    parallaxEquipa
      //.from(this.children[1], 0.4, {autoAlpha:0.1, ease:Power0.easeNone}, 0.4)
      .from(this.children[0], 2, { y: "-50%", ease: Power0.easeNone }, 0);

    var slideParallaxScene = new ScrollMagic.Scene({
      triggerElement: this,
      triggerHook: 1,
      duration: "180%",
    })
      .setTween(parallaxEquipa)
      // .addIndicators({
      // 	name:'parallax',
      // 	color: 'red'
      // })
      .addTo(controller);
  });
  //}
  // #######################################################################
  // ############## FIM  PARALLAX  #########################################
  // #######################################################################

  // #######################################################################
  // #################  CONTADOR  ##########################################
  // #######################################################################

  var init_contagem = new ScrollMagic.Scene({
    triggerElement: ".contador",
    triggerHook: 0.9,
  })
    // .addIndicators({
    //   name: "contador",
    //   colorStart: "yellow",
    // })
    .on("start", function () {
      $(".contador span").each(function () {
        var $this = $(this),
          countTo = $this.attr("data-count");

        $({ countNum: $this.text() }).animate(
          {
            countNum: countTo,
          },

          {
            duration: 2000,
            easing: "linear",
            step: function () {
              $this.text(Math.floor(this.countNum));
            },
            complete: function () {
              $this.text(this.countNum);
              //alert('finished');
            },
          }
        );
      });
    })
    .addTo(controller);

  // #######################################################################
  // #################  FIM CONTADOR  ######################################
  // #######################################################################
}); //jQuery

jQuery(document).ready(function ($) {
  //Para poder usar luxon
  let DateTime = luxon.DateTime;

  //################################################
  //               GERAL
  //################################################

  //label aparece quando se faz focus no input
  $(document).on("focus", ".checkin, .checkout", function () {
    $(this).parent().parent().find("label").addClass("opacity1");
  });

  $(document).on("click", ".guest", function () {
    if ($(this).val() == "") {
      $(this).parent().parent().find("label").toggleClass("opacity1");
    }
  });

  //Esconder contas guest quando se clica fora da div
  $(document).click(function (event) {
    if ($(event.target).parents(".navbar-collapse").length < 1) {
      var clickover = $(event.target);
      var $navbar = $(".navbar-collapse");
      var _opened = $navbar.hasClass("show");
      if (_opened === true && !clickover.hasClass("navbar-toggle")) {
        $navbar.collapse("hide");

        if ($(".guest").val() == "") {
          $(".guest").parent().parent().find("label").removeClass("opacity1");
        }
      }
    }
  });

  //Limpar a data quando se clica em .clear-checkin-date
  $(document).on("click", ".clear-checkin-date", function () {
    $(this).removeClass("d-block").parent().find("input").val("");
    $(this).parent().parent().find("label").removeClass("opacity1");
    to.datepicker("option", "minDate", dia);

    $(".reserva-result-content").addClass("hidden");
  });

  //Limpar a data quando se clica em .clear-checkout-date
  $(document).on("click", ".clear-checkout-date", function () {
    $(this).removeClass("d-block").parent().find("input").val("");
    $(this).parent().parent().find("label").removeClass("opacity1");
    from.datepicker("option", "maxDate", 730);

    $(".reserva-result-content").addClass("hidden");
  });

  //################################################
  //             FIM GERAL
  //################################################

  //################################################
  //               DATEPICKER
  //################################################

  $.datepicker.regional["pt"] = {
    closeText: "Fechar",
    prevText: "&#x3c;Anterior",
    nextText: "Seguinte",
    currentText: "Hoje",
    monthNames: [
      "Janeiro",
      "Fevereiro",
      "Mar&ccedil;o",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro",
    ],
    monthNamesShort: [
      "Jan",
      "Fev",
      "Mar",
      "Abr",
      "Mai",
      "Jun",
      "Jul",
      "Ago",
      "Set",
      "Out",
      "Nov",
      "Dez",
    ],
    dayNames: [
      "Domingo",
      "Segunda-feira",
      "Ter&ccedil;a-feira",
      "Quarta-feira",
      "Quinta-feira",
      "Sexta-feira",
      "S&aacute;bado",
    ],
    dayNamesShort: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "S&aacute;b"],
    dayNamesMin: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "S&aacute;b"],
    weekHeader: "Sem",
    dateFormat: "dd/mm/yy",
    firstDay: 0,
    isRTL: false,
    showMonthAfterYear: false,
    yearSuffix: "",
  };
  $.datepicker.setDefaults($.datepicker.regional["pt"]);

  let dia = new Date();
  dia.setDate(dia.getDate() + 3);

  var dateFormat = "dd-mm-yy",
    from = $("#checkin")
      .datepicker({
        dateFormat: "dd-mm-yy",
        minDate: 0,
        // defaultDate: "+1w",
        numberOfMonths: 2,
        onClose: function () {
          if ($(this).val() == "") {
            $(this).parent().parent().find("label").removeClass("opacity1");
          } else {
            $(this).parent().find(".clear-date").addClass("d-block");
          }
        },
      })
      .on("change", function () {
        let fromDate = getDate(this);
        let toMinDate = getDate(this);
        if (fromDate) {
          toMinDate.setDate(toMinDate.getDate() + 3);
        } else {
          toMinDate = dia;
        }
        to.datepicker("option", "minDate", toMinDate);
      }),
    to = $("#checkout")
      .datepicker({
        dateFormat: "dd-mm-yy",
        minDate: dia,
        numberOfMonths: 2,
        onClose: function () {
          if ($(this).val() == "") {
            $(this).parent().parent().find("label").removeClass("opacity1");
          } else {
            $(this).parent().find(".clear-date").addClass("d-block");
          }
        },
      })
      .on("change", function () {
        let toDate = getDate(this);
        let fromMaxDate = getDate(this);
        if (toDate) {
          fromMaxDate.setDate(fromMaxDate.getDate() - 3);
        } else {
          fromMaxDate = new Date();
          fromMaxDate.setDate(fromMaxDate.getDate() + 730);
        }
        from.datepicker("option", "maxDate", fromMaxDate);
      });

  function getDate(element) {
    var date;
    try {
      date = $.datepicker.parseDate(dateFormat, element.value);
    } catch (error) {
      date = null;
    }

    return date;
  }

  //################################################
  //             FIM DATEPICKER
  //################################################

  //################################################
  //           CONTAS DE GUESTS
  //################################################
  let max_guest = 18;

  $(document).on("click", ".adultos-mais", function (e) {
    e.preventDefault();

    let target = $(this).parent().find(".result");
    let valor_actual = parseInt(target.val());
    let novo_valor = (valor_actual += 1);
    target.val(novo_valor);

    if (novo_valor > 0) {
      $(".adultos-menos, .criancas-mais, .bebes-mais")
        .attr("disabled", false)
        .css("border-color", "#000");
    }

    contasTexto();
  });

  $(document).on("click", ".adultos-menos", function (e) {
    e.preventDefault();

    let target = $(this).parent().find(".result");
    let valor_actual = parseInt(target.val());
    let novo_valor = (valor_actual -= 1);
    target.val(novo_valor);

    if (novo_valor <= 0) {
      $(this).attr("disabled", "true");
      $(".criancas-menos, .criancas-mais, .bebes-menos, .bebes-mais").attr(
        "disabled",
        true
      );
      $(".result-criancas, .result-bebes").val(0);
    }

    contasTexto();
  });

  $(document).on("click", ".criancas-mais", function (e) {
    e.preventDefault();

    let target = $(this).parent().find(".result");
    let valor_actual = parseInt(target.val());
    let novo_valor = (valor_actual += 1);
    target.val(novo_valor);

    if (novo_valor > 0) {
      $(".criancas-menos").attr("disabled", false);
    }

    if (novo_valor == max_guest) {
      $(this).attr("disabled", true);
    }

    contasTexto();
  });

  $(document).on("click", ".criancas-menos", function (e) {
    e.preventDefault();

    let target = $(this).parent().find(".result");
    let valor_actual = parseInt(target.val());
    let novo_valor = (valor_actual -= 1);
    target.val(novo_valor);

    if (novo_valor < max_guest) {
      $(".criancas-mais").attr("disabled", false);
    }

    if (novo_valor == 0) {
      $(this).attr("disabled", "true");
    }

    contasTexto();
  });

  $(document).on("click", ".bebes-mais", function (e) {
    e.preventDefault();

    let target = $(this).parent().find(".result");
    let valor_actual = parseInt(target.val());
    let novo_valor = (valor_actual += 1);
    target.val(novo_valor);

    if (novo_valor > 0) {
      $(".bebes-menos").attr("disabled", false);
    }

    if (novo_valor == max_guest) {
      $(this).attr("disabled", true);
    }

    contasTexto();
  });

  $(document).on("click", ".bebes-menos", function (e) {
    e.preventDefault();

    let target = $(this).parent().find(".result");
    let valor_actual = parseInt(target.val());
    let novo_valor = (valor_actual -= 1);
    target.val(novo_valor);

    if (novo_valor < max_guest) {
      $(".bebes-mais").attr("disabled", false);
    }

    if (novo_valor == 0) {
      $(this).attr("disabled", "true");
    }

    contasTexto();
  });

  //Limpar qtd de guests quando se clica em limpar
  $(document).on("click", ".limpar", function () {
    $(".result-adultos, .result-criancas, .result-bebes").val(0);
    $(
      ".adultos-menos, .criancas-menos, .criancas-mais, .bebes-menos, .bebes-mais"
    ).attr("disabled", true);

    contasTexto();
  });

  function contasTexto() {
    let totalGuests = 0;

    let adultos = parseInt($(".result-adultos").val());
    let criancas = parseInt($(".result-criancas").val());
    let bebes = parseInt($(".result-bebes").val());
    let resultTxt = "";

    totalGuests = adultos + criancas + bebes;
    $(".result-total-guests").val(totalGuests);

    if (totalGuests == max_guest) {
      $(".adultos-mais, .criancas-mais, .bebes-mais").attr("disabled", true);
    } else {
      if ($(".result-adultos").val() == 0) {
        $(".adultos-mais").attr("disabled", false);
      } else {
        $(".adultos-mais, .criancas-mais, .bebes-mais").attr("disabled", false);
      }
    }

    if (adultos > 0) {
      resultTxt = adultos + " A";

      if (criancas > 0) {
        resultTxt += ", " + criancas + " C";
      }
      if (bebes > 0) {
        resultTxt += ", " + bebes + " B";
      }
    }

    $(".guest").val(resultTxt);

    tudoPreenchido(); //Verificar se está tudo preenchido
  }

  //################################################
  //         FIM CONTAS DE GUESTS
  //################################################

  //################################################
  //         RESERVA-RESULT-CONTENT
  //################################################

  $(document).on("change", ".checkin, .checkout, .guest", function () {
    tudoPreenchido();
  });

  function tudoPreenchido() {
    let checkin = $("#checkin").val();
    let checkout = $("#checkout").val();
    let guest = $("#guest").val();
    console.log(guest);

    if (checkin && checkout && guest) {
      /*## CALCULOS ##*/
      let preco_noite = 100;
      let taxa_servico_noite = 10;

      let date_checkin = DateTime.fromFormat(checkin, "dd-LL-yy");
      let date_checkout = DateTime.fromFormat(checkout, "dd-LL-yy");

      let num_noites = date_checkout.diff(date_checkin, "days");

      let total_noites = num_noites.toObject();

      let total_num_noites = total_noites.days;
      $(".total-noites-span").html(total_num_noites);
      $(".total-noites").val(total_num_noites);

      let total_euros_noite = total_num_noites * preco_noite;
      $(".total-noites-euros").val(total_euros_noite);

      let total_taxa_servico = total_num_noites * taxa_servico_noite;
      $(".taxa-servico").val(total_taxa_servico);

      let total_euros = total_euros_noite + total_taxa_servico;
      $(".total-euros").val(total_euros);

      /*## FIM CALCULOS ##*/

      $(".reserva-result-content").removeClass("hidden");
    } else {
      $(".reserva-result-content").addClass("hidden");
    }
  }

  //################################################
  //         FIM RESERVA-RESULT-CONTENT
  //################################################

  //################################################
  //         RESERVAR-SUITE
  //################################################

  $(document).on("click", "#reservar-suite", function (e) {
    e.preventDefault();

    // let preco_noite = 100;
    // let taxa_servico_noite = 10;

    let checkin = $("#checkin").val();
    let checkout = $("#checkout").val();

    // let date_checkin = DateTime.fromFormat(checkin, "dd-LL-yy");
    // let date_checkout = DateTime.fromFormat(checkout, "dd-LL-yy");

    // let num_noites = date_checkout.diff(date_checkin, "days");

    // let total_noites = num_noites.toObject();

    // let total_num_noites = total_noites.days;
    // $(".total-noites-span").html(total_num_noites);
    // $(".total-noites").val(total_num_noites);

    // let total_euros_noite = total_num_noites * preco_noite;
    // $(".total-noites-euros").val(total_euros_noite);

    // let total_taxa_servico = total_num_noites * taxa_servico_noite;
    // $(".taxa-servico").val(total_taxa_servico);

    // let total_euros = total_euros_noite + total_taxa_servico;
    // $(".total-euros").val(total_euros);
  });

  //################################################
  //         FIM RESERVAR-SUITE
  //################################################
}); //jQuery

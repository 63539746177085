

//lightGallery(document.getElementById('lg-galeria'));



jQuery(document).ready(function($){


// $('.mudar-cor').on("click", function(){
// 	$('.titulo').css('color', 'grey');
// });

	$('.slider-slide').slick({
		dots: true,
		appendDots: $('.slides-dots'),
		appendArrows: $('.slides-setas'),
		prevArrow: '<div class="slides-seta-esquerda"></div>',
		nextArrow: '<div class="slides-seta-direita"></div>'

	});


 
	$('.outros-produtos').slick({
		slidesToShow: 4,
		slidesToScroll: 4,
		dots: true,
		appendDots: $('.slides-produtos-dots'),
		appendArrows: $('.slides-produtos-setas'),
		prevArrow: '<div class="slides-produtos-seta-esquerda"></div>',
		nextArrow: '<div class="slides-produtos-seta-direita"></div>',
		responsive: [
			{
				breakpoint:1120,
				settings: {
					slidesToShow: 2
				}
			}
		]
	});


	//jQuery to collapse the navbar on scroll
	// $(window).scroll(function() {
	// 	if ($(".navbar").offset().top > 50) {
	// 		$(".navbar-fixed-top").addClass("top-nav-collapse");
	// 		$('.navbar-nav .btn').css('color', '#f6ca46');
	// 	} else {
	// 		$(".navbar-fixed-top").removeClass("top-nav-collapse");
	// 		$('.navbar-nav .btn').css('color', '#f5f5f5');
	// 	}
	// 	if ( $('body').hasClass('admin-bar') ) {
	// 		if ( $(window).width() < 768 ) {
	// 			if ( $('.navbar').offset().top < 46 ) {
	// 				$('.navbar').css('top', '46px');
	// 			} else {
	// 				$('.navbar').css('top', '0px');
	// 			}
	// 		} else {
	// 			$('.navbar').css('top', '32px');
	// 		}
	// 	}
	// });


	$('a[data-rel^=lightcase]').lightcase({
		transition: 'scrollRight',
		transitionOut: 'scrollBottom',
		showTitle: true,
		maxWidth: '100%', 
		maxHeight: '100%',
		shrinkFactor: 0.85,
 
		iframe: {
			width: '100%',
			height: function () { return $(window).outerHeight()}
		}
		
	})




});  //document ready

